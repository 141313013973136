@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

body {
  background-color: #ffffff41;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin: 50px;
  height: 73vh;
  padding-left: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-top: 40px;
  background-color: #86868633;
  border-radius: 15px;
  box-shadow: 0px 5px 21px -7px rgba(0, 0, 0, 0.75);
}

.pagename {
  display: flex;
  align-items: center;
  color: #121b74;
  font-weight: bold;
  font-size: x-large;
  margin: 0 30px;
  padding-top: 1em;
}

.subject {
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
  text-decoration: underline;
}

.client_zuidzorg {
  color: #afca0b;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_buurtzorgnederland {
  color: #1944ac;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_buurtzorgt {
  color: #e20f08;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_buurtdiensten {
  color: #008cff;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_accoladezorg {
  color: #a613b6;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_elver {
  color: #04928b;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.client_plu {
  color: #00eeff;
  font-size: large;
  font-weight: bold;
  padding-bottom: 6px;
}

.sub-content {
  position: absolute;
  height: fit-content;
  width: fit-content;
  padding: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #121b74;
  margin: 0 30px; /* Margins tussen items */
  margin-top: 30px; /* Margins tussen items */

  border-right: 1px solid #7c7b7b;
  border-bottom: 1px solid #7c7b7b;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 5px 21px -7px rgba(0, 0, 0, 0.75);
}

table {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-rows: repeat(2, 1fr);
  border-spacing: 25px;
}

th,
td {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #121b74;
  min-width: 160px;
  max-width: auto;
  min-height: 180px;
  max-height: auto;
  border: 0.01em solid #58585a62;
  padding: 15px; /* Voegt padding toe aan de cellen */
  text-align: left; /* Centreert de tekst in de cellen */
  vertical-align: top;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 10px 10px 17px -6px rgb(64, 64, 64);
}

.count{
  padding-bottom: 4px;
}

.loader {
  margin-left: 10px;
}

.loginbutton {
  margin: 0 30px; /* Margins tussen items */
  padding-top: 1em;
}

.logoutbutton {
  font-family: "Roboto", sans-serif;
  margin: 0 30px; /* Margins tussen items */
  padding-top: 1em;
  color: #121b74;
  font-weight: bold;
  text-decoration: none;
}

.edit-button {
  border-radius: 5px;
  background-color: #121b74; /* Green */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
